:global(.modal--active .backdrop){
    display: flex;
    width: 100%;
	height: 100%;
	z-index: 2;
	background-color: #282828bc;
	top: 0;
	position: fixed;
	backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.5);
}

.popup__layout{
    background: 
        linear-gradient(#0B0F12 0 0) padding-box, /*this is your grey background*/
        linear-gradient(to right, #44FAD7 0%, #5B54DB 28%, #000 100%) border-box;
    color: #313149;
    border: 1px solid transparent;
    border-radius: 50px;
    box-shadow: 1px 4px 7px -5px lightblue;

	position: absolute;
	z-index: 3;

	/* background: #F8F9FA; */

	left: 0; 
    right: 0; 
    margin: 0 auto;
    top: 120px;

	overflow: overlay;
	overscroll-behavior: contain;

	/* color: #333; */
	cursor: initial;

	width: calc(100% - 15px);
    max-width: 600px;
	/* border-radius: 30px; */

	padding: 25px;
	box-sizing: border-box;
}


@media only screen and (max-height: 815px){
    .popup__layout{
        top: 15px;
    }
}
