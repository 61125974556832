.section{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 100px;
    z-index: 1;
}
.left{
    width: 40%;
}
.right{
    width: 50%;
}
.title{
    font-family: var(--third-family);
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 0.01em;
    color: #fff;
    margin-bottom: 16px;
}
.description{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 24px;
    color: rgba(255, 255, 255, 0.5);
}
.row{
    display: flex;
    margin-bottom: 2px;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 8px;
}
.img_wrapper_half{
    width: 50%;
    max-width: 250px;
    height: 250px;
    border-radius: 40px;
    padding: 8px;
    backdrop-filter: blur(8px);
    background: linear-gradient(143deg, rgba(255, 255, 255, 0.05) 0%, rgba(153, 153, 153, 0.05) 100%);
    position: relative;
    box-sizing: border-box;
}
.img_wrapper_half:first-child{
    margin-right: 8px;
}
.img_wrapper_full{
    width: 100%;
    max-width: 500px;
    height: 250px;
    border-radius: 40px;
    padding: 8px;
    backdrop-filter: blur(8px);
    background: linear-gradient(143deg, rgba(255, 255, 255, 0.05) 0%, rgba(153, 153, 153, 0.05) 100%);
    position: relative;
    box-sizing: border-box;
}
.arrow{
    position: absolute;
    right: 16px;
    top: 16px;
    width: 40px;
}
.pic{
    height: 100%;
    width: 100%;
}
.label{
    position: absolute;
    bottom: 8px;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.5) 53.73%, rgba(0, 0, 0, 0.25) 100%);
    width: calc(100% - 16px);
    text-align: center;
    padding: 8px 0;
    border-radius: 0 0 100px 100px;
    
}
.img_wrapper_half .label{
    max-width: 234px;
}
.img_wrapper_full .label{
    max-width: 484px;
}
@media only screen and (max-width: 1280px){
    .description{
        font-size: 16px;
    }
    .img_wrapper_half{
        max-width: 200px;
        max-height: 200px;
        border-radius: 30px;
    }
    .img_wrapper_full{
        max-width: 400px;
        max-height: 200px;
        border-radius: 30px;
    }
    .label{
        border-radius: 0 0 20px 20px;
    }

    .arrow{
        right: 14px;
        top: 14px;
        width: 32px;
    }
}
@media only screen and (max-width: 1024px){
    .left{
        width: 35%;
    }  
    .right{
        width: 60%;
    }
}
@media only screen and (max-width: 800px){
    .section{
        flex-direction: column;
    }
    .left{
        width: 100%;
        margin-bottom: 16px;
    }
    .right{
        width: 100%;
    }
    .img_wrapper_half{
        max-width: 250px;
        max-height: 250px;
    }
    .img_wrapper_full{
        max-width: 500px;
        max-height: 250px;
    }

    .arrow{
        right: 16px;
        top: 16px;
        width: 40px;
    }
}
@media only screen and (max-width: 600px){
    .section{
        margin-bottom: 50px;
    }
    .img_wrapper_half{
        max-width: 200px;
        max-height: 200px;
    }
    .img_wrapper_full{
        max-width: 400px;
        max-height: 200px;
    }

    .arrow{
        right: 14px;
        top: 14px;
        width: 32px;
    }
}