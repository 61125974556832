.footer{
    width: 100%;
    color: #fff;
    z-index: 1;
    padding: 64px;
    /* margin-top: 100px; */
}
.upper{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.left,
.middle,
.right{
    width: 33%;
}
/* Title */
.title{
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: #fff;
    margin-bottom: 16px;
}
.record{
    display: flex;
    margin-bottom: 24px;
    align-items: flex-start;
}
.contact_icon{
    margin-right: 12px;
    height: 40px;
    padding: 8px;
    box-sizing: border-box;

    /* border-image:  */
    background: 
        linear-gradient(#0B0F12 0 0) padding-box, /*this is your grey background*/
        linear-gradient(to right, #44FAD7 0%, #5B54DB 28%, #000 80%) border-box;
    color: #313149;
    border: 1px solid transparent;
    border-radius: 12px;
}
.info_block{
    display: flex;
    flex-direction: column;
    font-size: 16px;
}
.type{
    font-weight: 200;
    color: rgba(255, 255, 255, 0.5);
}
.contact{
    color: #fff;
}
/* LEFT */
.buttons_block{
    display: flex;
    margin-top: 32px;
}

.telegram,
.whatsapp{
    border-radius: 16px;
    padding: 8px 32px;
    background: rgba(0, 176, 242, 0.25);
    display: flex;
    align-items: center;
    font-size: 16px;
}
.telegram{
    font-family: var(--font-family);
    color: #00b0f2;
    margin-right: 16px;
}
.whatsapp{
    font-family: var(--font-family);
    color: #00e510;
}
.telegram img,
.whatsapp img{
    margin-right: 8px;
}

/* MIDDLE */
.working_hours{
    margin: 0 16px 24px 0;
}

/* RIGHT */
.country{
    margin: 12px 0 0 36px;
}
.buttons_maps_block{
    display: flex;
    margin: 24px 0;
    justify-content: space-between;
    /* width: 75%; */
}
.map_item{
    width: 33%;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.map_item img{
    margin-bottom: 12px;
}

.map_scheme_block{
    display: flex;
    justify-content: center;
    /* width: 75%; */
}
.map_scheme_button{
    border: 1px solid #00796d;
    border-radius: 16px;
    padding: 8px 32px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 24px;
    
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.04em;
    color: #ffffff;

    background: 
        linear-gradient(#0B0F12 0 0) padding-box, /*this is your grey background*/
        linear-gradient(to right, #44FAD7 0%, #5B54DB 28%, #000 100%) border-box;
    border: 1px solid transparent;
    border-radius: 50px;
}

/* BOTTOM */
.bottom_block{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, 0.28);
    border-bottom: 1px solid rgba(255, 255, 255, 0.28);
    margin: 64px 0 0 0;
    padding: 16px 0;
}
.bottom_left{
    display: flex;
    align-items: center;
}
.label_follow{
    margin-right: 16px;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 24px;
    color: #fff;
}
.bottom_left img{
    margin: 0 16px;
    cursor: pointer;
}
.bottom_right{
    display: flex;
    align-items: center;
}
.bottom_right button{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 16px;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-align: center;
    color: #fff;
    margin-left: 48px;
}
/*  */
@media only screen and (max-width: 1280px){
    .telegram, .whatsapp{
        padding: 8px 16px;
    }
}
@media only screen and (max-width: 1025px){
    .upper{
        flex-wrap: wrap;
    }
    .left, .middle{
        width: 50%;
    }
    .left{
        order: 1;
    }
    .middle{
        order: 3;
    }
    .right{
        justify-content: center;
        width: 50%;
        order: 2;
    }
    .buttons_maps_block{
        margin: 24px 0 0;
    }
}
@media only screen and (max-width: 769px){
    .left, .middle, .right{
        width: 100%;
        
    }
    .left{
        order: 1;
        margin-bottom: 48px;
        
    }
    .middle{
        order: 2;
        margin-bottom: 24px;
    }
    .right{
        order: 3;
        margin-bottom: 48px;
    }
    .telegram, .whatsapp{
        padding: 8px 24px;
    }
}