.section{
    backdrop-filter: blur(26px);
    background: rgba(22, 22, 22, 0.01);
    display: flex;
    justify-content: space-between;
    padding: 64px;
    width: 90%;
    
    /* border-image:  */
    background: 
        linear-gradient(#0B0F12 0 0) padding-box, /*this is your grey background*/
        linear-gradient(to right, #44FAD7 0%, #5B54DB 28%, #000 100%) border-box;
    color: #313149;
    border: 1px solid transparent;
    border-radius: 50px;
}
.left{
    width: 50%;
}
.right{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 40px;
    color: #fff;
    margin-bottom: 32px;
}
.text{
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 130%;
    color: rgba(255, 255, 255, 0.5);
}
.btn{
    border-radius: 48px;
    padding: 16px 32px;
    background: #fff;
    font-family: var(--second-family);
    font-weight: 600;
    font-size: 20px;
    color: #161616;
}