/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

html, body {
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #F8F9FA;
}

*{
  font-family: 'Montserrat', sans-serif;
}
a{
  text-decoration: none;
}
h1, h2, h3, h4, h5, h6, p, ul, li{
  margin: 0;
  padding: 0;
}
ul{
  list-style-type: none;
}
button{
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent; 
}

.selectDisable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.disabled{
  filter: grayscale(100%);
  opacity: 0.5;
}

:root {
	--accent: #4cfffb;
  --font-family: "DM Sans", sans-serif;
  --second-family: "Open Sans", sans-serif;
}

#root{
  width: 100%;
  /* height: 100%; */
  /* background: linear-gradient(90deg, #D3DFF8 0%, #DFF7FF 36.11%, #DFF7FF 61.11%, #D3DFF8 100%); */
  background: #F8F9FA;
}


/**********************************************************************************/
/************************************ VISITOR *************************************/
/**********************************************************************************/


/* CARD */
.card__wrapper{
  border-radius: 30px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0.19%, rgba(255, 255, 255, 0.00) 99.8%);
  box-shadow: 3px -1px 19.5px 0px rgba(0, 96, 183, 0.25);
  backdrop-filter: blur(5px);

  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  width: calc( 100% - 60px );
  margin: 0 auto;
}
.card__content{
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.05), 0px 22px 22px 0px rgba(0, 0, 0, 0.04), 0px 49px 30px 0px rgba(0, 0, 0, 0.03), 0px 87px 35px 0px rgba(0, 0, 0, 0.01), 0px 137px 38px 0px rgba(0, 0, 0, 0.00);
  color: #0060B7;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
}
.card__title{
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
}









/* MODAL */
.modal{
  position: fixed;
  inset: 0;
  z-index: 2;
}

.modal__container{
  position: absolute;
  inset: 0;
  /* display: flex;
  justify-content: center;
  background: rgba(30,32,46,.9);
  cursor: pointer; */
  overflow: auto;
  overscroll-behavior: contain;
}

.modal:not(.modal--active) {
  pointer-events: none;
}
.modal.modal--active {
  transform: none;
}


/* NEWS SWIPER */
.newsSwiper.swiper{
  padding: 25px 0;
}