.main{
    z-index: 1;
    width: calc(100% - 60px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    display: flex;
    position: relative;
    justify-content: space-evenly;
}
.left{
    width: 50%;
    margin-top: 15vh;
    z-index: 1;
}
.right{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}
/* LEFT */
.title{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 88px;
    line-height: 110%;
    color: #fff;
}
.subtitle{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 26px;
    color: rgba(255, 255, 255, 0.5);
}
.description{
    font-weight: 200;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.75);
    width: 70%;
    margin-top: 32px;
}
/* RIGHT */
.banner{
    width: 100%;
    z-index: 1;
}
.img{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}
.ellipse{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-60%, -50%);
    z-index: 0;
    width: 1000px;
    height: 1000px;
}

@media only screen and (max-width: 1280px){
    .title{
        font-size: 70px;
    }
    .subtitle{
        font-size: 20px;
    }
    .description{
        font-size: 16px;
        width: 70%;
        margin-top: 32px;
    }
}

@media only screen and (max-width: 1024px){
    .main{
        flex-direction: column;
        justify-content: flex-start;
    }
    .left{
        width: 100%;
        margin-top: 5vh;
        text-align: center;
    }
    .description{
        width: 100%;
    }
    .right{
        width: 100%;
    }
    .right .banner{
        height: 100%;
        width: 90%;
        margin-top: 50px;
    }
}

@media only screen and (max-width: 550px){
    .right .banner{
        width: 100%;
        max-height: 50vh;
    }
    .title{
        font-size: 50px;
    }
    .subtitle{
        font-size: 16px;
    }
    .description{
        font-size: 16px;
    }
    .right .banner{
        margin-top: 100px;
    }
}