:global(#root){
    background-color: #0B0F12;
    overflow-x: hidden;
}
.v_layout{
    width: 100%;
    max-width: 2000px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    padding: 80px 5% 0;
    border-width: 1px;
    border-top: none;
    border-bottom: none;
    border-style: solid;
    /* border-color: #0B0F12; */
    /* box-shadow: 3px -1px 19.5px 0px rgba(0, 96, 183, 0.25); */
    position: relative;
}

.main{
    z-index: 1;
    width: 100%;
    padding: 0 0 100px 0;
}
.text {
    background-color: transparent;
    color: #00CCCC;
    font-family: monospace;
    margin: .25rem;
  }

.text:first-child {
    margin-bottom: 1rem;
}

.text:last-child {
    margin-top: 1rem;
}

.text.caret::after {
animation: blink-caret 1s step-end infinite;
color: inherit;
content: '_';
font-weight: bolder;
}

/* .flyAround {
    position: absolute;
    transition: transform 2s ease-in-out;
} */
  

@keyframes blink-caret {
    from, to {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

@media only screen and (max-width: 550px){
    /* :global(#root){
        border-radius: 30px;
    }
    .v_layout{
        border-radius: 30px;
        box-shadow: none;
    } */
}